<template>
  <div class="index card-details">
    <van-nav-bar title="招牌菜劵码" left-arrow @click-left="onBack" />
    
    <div class="coupon" >
      <div class="rest-name" @click="onRestaurant(card.restaurantId)"> 
          <van-icon name="shop-o" /> 
          {{card.restaurantName}}  {{card.cookingStyleName}}   ¥{{card.pricePerPerson}}/人
          <van-icon name="arrow" />
          
      </div>
      <van-row>
        <van-col span="8">
          <van-image :src="card.imageUrl" width="95%" height="92px"  />
        </van-col>
        <van-col span="15" offset="1">
          <div class="coupon-detail">
            <div class="coupon-name">{{card.productName}}</div>
            <div>
                <span class="price">¥{{card.price}}</span> 
                <span class="ori-price">原价:¥{{card.originPrice}}</span>
            </div>
            <div class="coupon-info" v-if="!card.verified">
              <span>有效期至: </span> <span class="time">2021.12.29</span>
            </div>
            
            <div class="coupon-info" v-if="card.verified">
              <span>消费时间:</span> 
              <span class="time">{{card.verifiedTime}}</span>
            </div>
          </div>
        </van-col>
      </van-row>
    </div>
    
    <div class="coupon-detailll">
      <van-skeleton title :row="4" :loading="loading">
        <div class="qr-code" :class="card.verified ? 'used': ''" @click="refreshImage">
          <van-image :src="secretUrl" />
        </div>
        <div class="num-code" :class="card.verified ? 'used': ''">动态验证码：{{ code }}</div>
        <div class="button-row">
          <span class="coupon-status" v-if="card.verified">已消费</span>
          <span class="coupon-status" v-else>未消费</span>
          <van-button v-if="!card.verified" plain  @click="onCancelOrder">申请退款</van-button>
          <div v-if="card.verified" class="coupon-info"><span>消费时间：</span><span class="time">2021.12.24 11:30</span></div>
        </div>
      </van-skeleton>
    </div>
    <!-- <div class="refund-reason">
      <div class="coupon-info">
        <span>退款理由：</span>
        <span class="time">没时间去了</span>
      </div>
    </div> -->
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import CardService from "../api/card-service";
import "../css/common.scss";
import "../css/my-cards.scss";
import "../css/card-details.scss";

export default {
  components: {},
  setup() {
    const state = reactive({
      card: {},
      cardId: 0,
      code: "",
      secretUrl: "",
      loading: true,
    });
    const router = useRouter();
    const { cardId } = useRoute().params;
    state.cardId = cardId;
    const onBack = () => router.back();
    const refreshImage = () =>
      CardService.getCardNumber(state.card.orderId).then((num) => {
        state.code = num;
        state.secretUrl = CardService.getCardSecretUrl(state.card.orderId);
        state.loading = false;
        createRefreshImage();
      });
    const createRefreshImage = () => setTimeout(refreshImage, 60 * 1000);
    onMounted(() => {
      CardService.getCard(cardId).then((card) => {
        state.card = card;
        refreshImage();
      });
    });

    return {
      ...toRefs(state),
      onBack,
      refreshImage,
    };
  },
};
</script>
